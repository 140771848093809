<template>
  <SubNav
    class="subnav-position"
    :selected="'tenants'"
    :buildingId="buildingId"
  />
  <main>
    <form v-if="tenant" @submit.prevent="handleSave" class="edit-form">
      <div class="float-end">
        <router-link
          :to="{ name: 'BuildingTenants', params: { buildingId: buildingId } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Edit Tenant</h6>
      <div class="form-outline mb-4">
        <label>Company Name</label>
        <input
          type="text"
          required
          v-model="tenant.companyName"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Contact Name</label>
        <input
          type="text"
          required
          v-model="tenant.contactName"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Contact Email</label>
        <input type="text" v-model="tenant.contactEmail" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Contact Phone</label>
        <input type="text" v-model="tenant.contactPhone" class="form-control" />
      </div>

      <div class="error">{{ errorForm }}</div>
      <div v-if="!isPending" class="row text-center">
        <div v-if="!errorForm" class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div v-else class="col">
          <button class="btn btn-secondary" disabled>Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'BuildingTenants',
              params: { buildingId: buildingId }
            }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            Delete Tenant
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import { timestamp } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import useDocument from "@/composables/useDocument";
import getDocument from "@/composables/getDocument";
import { useRouter } from "vue-router";
export default {
  props: ["buildingId", "tenantId"],
  components: { SubNav },
  setup(props) {
    const isPending = ref(false);
    const errorForm = ref(null);
    const router = useRouter();

    const {
      error: errorUpdateTenant,
      deleteDoc: deleteTenant,
      updateDoc: updateTenant
    } = useDocument("tenants", props.tenantId);

    const { error: errorGetTenant, document: tenant } = getDocument(
      "tenants",
      props.tenantId
    );

    const handleSave = async () => {
      try {
        isPending.value = true;
        errorForm.value = null;
        await updateTenant({
          companyName: tenant.value.companyName,
          contactName: tenant.value.contactName ? tenant.value.contactName : "",
          contactEmail: tenant.value.contactEmail
            ? tenant.value.contactEmail
            : "",
          contactPhone: tenant.value.contactPhone
            ? tenant.value.contactPhone
            : "",
          editedAt: timestamp()
        });
        isPending.value = false;
        if (errorUpdateTenant.value) {
          errorForm.value = errorUpdateTenant.value;
        } else {
          router.push({
            name: "BuildingTenants",
            params: { buildingId: props.buildingId }
          });
        }
      } catch (err) {
        isPending.value = false;
        errorForm.value = `System Error: ${err.message}`;
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      errorForm.value = null;

      await deleteTenant();
      isPending.value = false;
      if (errorUpdateTenant.value) {
        errorForm.value = errorUpdateTenant.value;
      } else {
        router.push({
          name: "BuildingTenants",
          params: { buildingId: props.buildingId }
        });
      }
    };
    return {
      isPending,
      errorForm,
      errorGetTenant,
      handleSave,
      handleDelete,
      tenant
    };
  }
};
</script>

<style>
</style>